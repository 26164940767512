import "bootstrap/dist/css/bootstrap.min.css";
import Routes from "./Routes";
import "./App.scss";
import React from "react";
const AuthUserProvider = React.lazy(() => import("./context/AuthContext"));
const ContractProvider = React.lazy(() => import("./context/ContractContext"));


function App() {
    return (
        <React.Suspense fallback={<div>...</div>}>
            <AuthUserProvider>
                <ContractProvider>
                    <Routes />
                </ContractProvider>
            </AuthUserProvider>
        </React.Suspense>
    );
}

export default App;
