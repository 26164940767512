import ReactDOM from "react-dom";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store from "./state/store";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import { ChakraProvider } from "@chakra-ui/react";

import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";

const gqlClientDecentraland = new ApolloClient({
    uri: "https://api.thegraph.com/subgraphs/name/decentraland/marketplace",
    cache: new InMemoryCache(),
});

if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
} else {
    console.log = () => {};
    console.error = () => {};
    console.warn = () => {};
}

let persistor = persistStore(store);

ReactDOM.render(
    // <React.StrictMode>
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <ApolloProvider client={gqlClientDecentraland}>
                <ChakraProvider>
                    <App />
                </ChakraProvider>
            </ApolloProvider>
        </PersistGate>
    </Provider>,
    // </React.StrictMode>,
    document.getElementById("root")
);

reportWebVitals();
