import { createSlice } from "@reduxjs/toolkit";
import { ToastType } from "../context/UIFunctions";

const initialState = {
  walletNet: undefined,
  walletAddr: undefined,
  shortAddr: undefined,
  balance: undefined,
  curCollection:undefined,
  marketPlace: { label: "OpenSea", slug: "opensea" },
  discoverOptions: {},
  discoveredNFTs: [],
  prevOffset: 0,
  firstLoad: true,
  discoverScrollOffset: 0,
  openSeaCollections: [],
  collectionSlug: 'undefined',
  isLoadingToken: false,
  
  searchValue: "",
  sorting: 'created_date',
  filterItem: undefined,
  coinFilter: undefined,
  orderDirection: "DESC",
  surveyAnswer: {},
  selToken: undefined,
  selOrder: undefined,
  afterLoginPath: undefined,
  curLoanPortfolio: undefined,
  walletBalanceList: [],
  nftCount: 0,

  loanStatusFilter: undefined ,

  showModalToast: false,
  modalToastTitle: '',
  modalToastSubTitle: '',
  modalToastType: ToastType.Info

};

export const rootSlice = createSlice({
  name: "root",
  initialState: initialState,
  reducers: {
    
    setNftCount: (state, action) => {
      state.nftCount = action.payload;
    },
    setWalletNet: (state, action) => {
      state.walletNet = action.payload;
    },
    setWalletAddr: (state, action) => {
      state.walletAddr = action.payload;
    },
    setMarketPlace: (state, action) => {
      state.marketPlace = action.payload;
    },
    setShortAddr: (state, action) => {
      state.shortAddr = action.payload;
    },
    setBalance: (state, action) => {
      state.balance = action.payload;
    },
    setCurCollection: (state, action) => {
      state.curCollection = action.payload;
    },
    setDiscoverOption: (state, action) => {
      const item = action.payload;
      state.discoverOptions[item.key] = item.value;
    },
    setDiscoverOptions: (state, action) => {
      const options = action.payload;
      if (!state.discoverOptions) {
        state.discoverOptions = {};
      }
      Object.keys(options).forEach((key) => {
        state.discoverOptions[key] = options[key];
      });
    },
    initDiscoverOptions: (state, action) => {
      state.discoverOptions = {};
    },
    setDiscoveredNFTs: (state, action) => {
      state.discoveredNFTs = action.payload;
    },
    setPrevOffset: (state, action) => {
      state.prevOffset = action.payload;
    },
    setFirstLoad: (state, action) => {
      state.firstLoad = action.payload;
    },
    setDiscoverScrollOffset: (state, action) => {
      state.discoverScrollOffset = action.payload;
    },
    setOpenSeaCollections: (state, action) => {
      state.openSeaCollections = action.payload;
    },
    setSearch: (state, action) => {
      state.searchValue = action.payload;
    },
    setSorting: (state, action) => {
      state.sorting = action.payload;
    },
    setFilterItem: (state, action) => {
      state.filterItem = action.payload;
    },
    setCoinFilter: (state, action) => {
      state.coinFilter = action.payload;
    },
    setOrderDirection: (state, action) => {
      state.orderDirection = action.payload;
    },
  
    setSurveyAnswer: (state, action) => {
      const { key, value } = action.payload;
      let newAnswer = {
        ...state.surveyAnswer,
      };
      newAnswer[key] = value;
      state.surveyAnswer = newAnswer;
    },
    initSurveyAnswer: (state, action) => {
      state.surveyAnswer = {};
    },
    setSelOrder: (state, action) => {
      state.selOrder = action.payload;
    },
    setSelToken: (state, action) => {
      state.selToken = action.payload;
    },

    setAfterLoginPath: (state, action) => {
      state.afterLoginPath = action.payload;
    },
    setCurLoanPortfolio: (state, action) => {
      state.curLoanPortfolio = action.payload;
    },

    setWalletBalanceList : (state, action) => {
      state.walletBalanceList = action.payload;
    },
    setCollectionSlug : (state, action) => {
      state.collectionSlug = action.payload;
    },
    setisLoadingToken : (state, action) => {
      state.isLoadingToken = action.payload;
    },
    setLoanStatusFilter : (state, action) => {
      state.loanStatusFilter = action.payload;
    },

    setShowModalToast : (state, action) => {
      state.showModalToast = action.payload;
    },

    setModalToastTitle : (state, action) => {
      state.modalToastTitle = action.payload;
    },
    setModalToastSubitle : (state, action) => {
      state.modalToastSubTitle = action.payload;
    },
    setModalToastType : (state, action) => {
      state.modalToastType = action.payload;
    },

    openModalToast:(state, action)=>{
      state.showModalToast = true;
      state.modalToastTitle = action.payload.modalToastTitle;
      state.modalToastSubTitle = action.payload.modalToastSubTitle;
      state.modalToastType = action.payload.modalToastType;

    },

    closeModalToast:(state, action) => {
      state.showModalToast = false;
      state.modalToastTitle = '';
      state.modalToastSubTitle = '';
      state.modalToastType = ToastType.Info;
    }

  },
});

// Action creators are generated for each case reducer function
export const {
  setWalletNet,
  setWalletAddr,
  setShortAddr,
  setBalance,
  setCurCollection,
  setDiscoverOption,
  initDiscoverOptions,
  setDiscoverOptions,
  setDiscoveredNFTs,
  setPrevOffset,
  setFirstLoad,
  setDiscoverScrollOffset,
  setMarketPlace,
  setOpenSeaCollections,
  setSearch,
  setSorting,
  setFilterItem,
  setCoinFilter,
  setOrderDirection,
  setSurveyAnswer,
  initSurveyAnswer,
  setSelOrder,
  setSelToken,
  setAfterLoginPath,
  setCurLoanPortfolio,
  setWalletBalanceList,
  setCollectionSlug,
  setNftCount,
  setisLoadingToken,
  loanStatusFilter,
  setLoanStatusFilter,

  setShowModalToast,
  setModalToastTitle,
  setModalToastSubitle,
  setModalToastType,

  openModalToast,
  closeModalToast


} = rootSlice.actions;

export default rootSlice.reducer;
