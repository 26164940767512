import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";

const LoanDetails = React.lazy(() => import("./components/LoanDetails" /* webpackChunkName: "LoanDetails-Routes" */));
const Welcome = React.lazy(() => import("./components/Welcome" /* webpackChunkName: "Welcome-Routes" */));
const Favorites = React.lazy(() => import("./components/Favorites" /* webpackChunkName: "Favorites-Routes" */));
const MyNFTs = React.lazy(() => import("./components/MyNFTs" /* webpackChunkName: "MyNFTs-Routes" */));

// const Transactions = React.lazy(() => import("./components/Transactions"));

const PortfolioDetails = React.lazy(() =>
  import("./components/MyNFTs/PortfolioDetails"/* webpackChunkName: "PortfolioDetails-Routes" */)
);

// const ImportNft = React.lazy(() => import("./components/ImportNft"));

const Routes = () => (
  <Router>
    <React.Suspense fallback={<div>...</div>}>
      <Route exact path="/" component={Welcome} />
    </React.Suspense>
    <React.Suspense fallback={<div>...</div>}>
      <Route exact path="/bookmarks" component={Favorites} />
    </React.Suspense>
    <React.Suspense fallback={<div>...</div>}>
      <Route exact path="/mynfts" component={MyNFTs} />
    </React.Suspense>
    <React.Suspense fallback={<div>...</div>}>
      <Route exact path="/mynfts/details" component={PortfolioDetails} />
    </React.Suspense>

    {/* <React.Suspense fallback={<div>...</div>}>
      <Route exact path="/transactions" component={Transactions} />
    </React.Suspense> */}
        
    <React.Suspense fallback={<div>...</div>}>
      <Route exact path="/loan-details" component={LoanDetails} />
    </React.Suspense>
  </Router>
);
export default Routes;
