import {
    Icon,
    // useToast,
    Box,
    IconButton,
    Text,
    HStack,
    Flex,
} from "@chakra-ui/react";

import {
    AiOutlineCloseCircle,
    AiOutlineExclamationCircle,
    AiFillWarning,
} from "react-icons/ai";
import { BsFillPatchCheckFill } from "react-icons/bs";
import { CloseIcon } from "@chakra-ui/icons";

export const ToastType = {
    Success: "success",
    Error: "error",
    Info: "info",
    Warning: "warning",
};

export const ToastRender = ({
    title,
    message,
    toastType,
    onClose,
    isModal,
}) => {
    let iconColor = "black";
    let iconBgColor = "white";

    let defTitle = "";
    let icon = AiOutlineExclamationCircle;
    if (toastType == ToastType.Error) {
        iconBgColor = "#faa3";
        iconColor = "red";
        icon = AiOutlineCloseCircle;
        defTitle = "Sorry";
    }
    if (toastType == ToastType.Success) {
        iconBgColor = "#afa3";
        iconColor = "green";
        icon = BsFillPatchCheckFill;
        defTitle = "Success!";
    }
    if (toastType == ToastType.Info) {
        iconBgColor = "#aaf3";
        iconColor = "blue";
        icon = AiOutlineExclamationCircle;
        defTitle = "Info";
    }
    if (toastType == ToastType.Warning) {
        iconBgColor = "#d9dc3223";
        iconColor = "#BAAA3A";
        icon = AiFillWarning;
        defTitle = "Warning";
    }

    defTitle = title ? title : defTitle;

    return (
        <Box
            p={0}
            bg="white"
            h="fit-content"
            boxShadow={"xl"}
            borderRadius="xl"
            position={"relative"}
            overflow="hidden"
            maxW="500px"
        >
            <HStack h="fit-content" alignItems="stretch">
                <Flex
                    w={isModal ? "55px" : "55px"}
                    position="relative"
                    mr={1}
                    bg={iconBgColor}
                    borderTopRightRadius={"500%"}
                    borderBottomRightRadius={"500%"}
                    justify="flex-end"
                    alignItems={"center"}
                >
                    <Icon as={icon} color={iconColor} w={6} h={6} mx={"15px"} />
                </Flex>
                <Box px={3} py={10}>
                    <Text fontWeight={"bold"}>{defTitle}</Text>
                    <Text>{message}</Text>
                </Box>
            </HStack>

            <IconButton
                variant={"ghost"}
                position="absolute"
                top={2}
                right={2}
                _active={{ outline: "none" }}
                _focus={{ outline: "none" }}
                icon={<CloseIcon w={3} h={3} />}
                onClick={onClose}
            />
        </Box>
    );
};

const TOAST_DURATION = 10000;


const showToast = ({
    toast,
    title = "",
    message = "",
    toastType = ToastType.Success,
    duration = 5000,
    isClosable = true,
}) => {
    toast({
        title: title,
        description: message,
        status: toastType,
        duration,
        isClosable,
    });
};

const showError = (
    toast,
    message = "",
    title = "",
    duration = TOAST_DURATION,
    isClosable = true,
    asModal = false
) => {
    toast({
        title: title,
        description: message,
        status: ToastType.Error,
        duration,
        isClosable,
        render: ({ id, onClose }) => (
            <ToastRender
                toastType={ToastType.Error}
                title={title}
                message={message}
                onClose={onClose}
            />
        ),

        containerStyle: {
            padding: "20px",
        },
    });
};

const showSuccess = (
    toast,
    message = "",
    title = "",
    duration = TOAST_DURATION,
    isClosable = true,
    asModal = false
) => {
    toast({
        title: title,
        description: message,
        status: ToastType.Success,
        duration,
        isClosable,
        render: ({ id, onClose }) => (
            <ToastRender
                toastType={ToastType.Success}
                title={title}
                message={message}
                onClose={onClose}
            />
        ),
    });
};

const showInfo = (
    toast,
    message = "",
    title = "",
    duration = TOAST_DURATION,
    isClosable = true,
    asModal = false
) => {
    toast({
        title: title,
        description: message,
        status: ToastType.Info,
        duration,
        isClosable,
        render: ({ id, onClose }) => (
            <ToastRender
                toastType={ToastType.Info}
                title={title}
                message={message}
                onClose={onClose}
            />
        ),
    });
};

const showWarning = (
    toast,
    message = "",
    title = "",
    duration = TOAST_DURATION,
    isClosable = true,
    asModal = false
) => {
    toast({
        title: title,
        description: message,
        status: ToastType.Warning,
        duration,
        isClosable,
        render: ({ id, onClose }) => (
            <ToastRender
                toastType={ToastType.Warning}
                title={title}
                message={message}
                onClose={onClose}
            />
        ),
    });
};


export {
    showError,
    showSuccess,
    showInfo,
    showToast,
    showWarning,
    // showModalToast,
    // setShowModalToast,
    // openModalToast,
    // closeModalToast,
};
